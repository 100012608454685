<template>
  <div class="content">
    <div class="main-block">
      <div class="text-block">
        <div class="title">
          千河科技
        </div>
        <div class="subtitle">
          專注遊戲軟體和手機遊戲<br>
          及程式的規劃開發
        </div>
        <div class="text">
          我們的團隊擁有豐富的遊戲開發和管理經驗，我們的信念是提供專業和創新的服務給顧客，並且深信良好的售後服務才是顧客滿意的保證，在過去數年中我們靠客戶相互引薦介紹，完成許多成功的案例，本公司以創新的思維規劃，滿足客戶的需求爲第一優先。
        </div>
      </div>
    </div>
    <div class="team-block">
      <div>
        <div class="description">
          以專業知識爲基礎，發展核心技術、品質控管和滿足顧客的需求，並且以高效率和低成本爲客戶提供最佳服務。我們提供從網頁、遊戲內容到管理後台等整套完整的遊戲營運平台，並且協助客戶快速熟悉營業流程。
        </div>
      </div>
      <div class="feature-section">
        <div class="feature-block">
          <div class="text"><span>200+ </span>頂級遊戲人才</div>
          <div class="content">擁有200+名熱愛遊戲的創意人才，爲我們的遊戲項目注入了獨特而令人難忘的元素。</div>
        </div>
        <div class="feature-block">
          <div class="text"><span>1+1＞2 </span>技術與創意</div>
          <div class="content">通過技術的力量，我們將創意變爲現實，創造出引人入勝的遊戲體驗。</div>
        </div>
        <div class="feature-block">
          <div class="text"><span>N+ </span>多年遊戲經驗</div>
          <div class="content">憑藉多年的遊戲開發經驗，我們的公司成爲了行業中的佼佼者。</div>
        </div>
      </div>
    </div>
    <div class="service-block">
      <div class="title">我們的服務</div>
      <div class="icon-group">
        <div v-for="idx in 6" :key="idx" class="icon-block">
          <img class="icon" :src="require(`@/assets/services/icon_${idx}.png`)" alt="">
          <div>
            {{ serviceList[idx-1] }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TheContent",
  data() {
    return {
      serviceList: [
        '手機遊戲及程式開發',
        '網頁設計',
        '購物車設計(架設)',
        '手機遊戲相關系統',
        '運動遊戲開發',
        '客製化軟體設計',
      ]
    }
  },
};
</script>

<style lang="scss" scoped>
.content {
  position: relative;

  .main-block {
    margin: -1%;
    padding-top: 52.2%;
    background-image: url("@/assets/main_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
  
    @media only screen and (max-width: 750px) {
      padding-top: 234.8%;
      background-image: url("@/assets/main_bg_mb.jpg");
    }
  
  
    .text-block {
      position: absolute;
      left: 12vw;
      top: 15vw;
      width: 40%;
      text-align: left;

      @media only screen and (max-width: 750px) {
        left: 5vw;
        top: 20vw;
        width: 90%;
      }

      .title {
        font-weight: 700;
        font-size: 8vw;
        line-height: 1.42;
        color: rgba(255, 255, 255, 0.1);

        @media only screen and (max-width: 750px) {
          margin-top: 8vw;
          font-size: 18vw;
          opacity: 0.45;
          color: rgba(255, 255, 255, 0.45);
        }
      }
    }

    .subtitle {
      margin: 1vw 0 1.5vw;
      font-size: 3vw;
      color: #fff;

      @media only screen and (max-width: 750px) {
        margin: 5vw 0;
        font-size: 7vw;
      }
    }
  
    .text {
      font-size: 1.3vw;
      letter-spacing: 0.445833px;
      line-height: 1.56;
      color: #fff;

      @media only screen and (max-width: 750px) {
        font-size: 4.5vw;
      }
    }

  }

  .team-block {
    background-color: #F0FCFC;
    padding: 8vw 13vw;
    color: black;
    text-align: left;

    @media only screen and (max-width: 750px) {
      padding: 25vw 8vw;
    }

    .description {
      font-size: 1.95vw;
      color: #1e302f;
      line-height: 2;

      @media only screen and (max-width: 750px) {
        margin-bottom: 10vw;
        font-size: 5vw;
      }
    }

    .feature-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media only screen and (max-width: 750px) {
        flex-direction: column;
      }
    }

    .feature-block {
      margin-top: 5vw;
      color: rgba(30, 48, 47, 0.8);

      @media only screen and (max-width: 750px) {
        margin-top: 15vw;
      }

      .text {
        margin-bottom: 2vw;
        font-size: 2vw;

        @media only screen and (max-width: 750px) {
          margin-bottom: 5vw;
          font-size: 8vw;
        }
      }

      .content {
        max-width: 22vw;
        font-size: 1vw;
        opacity: 0.8;

        @media only screen and (max-width: 750px) {
          max-width: 100%;
          font-size: 4.2vw;
        }
      }

      span {
        color: #057CF2
      }
    }

  }

  .service-block {
    padding: 5vw 13vw;
    color: black;

    @media only screen and (max-width: 750px) {
      padding: 25vw 8vw;
    }
    
    .title {
      font-size: 3vw;
      color: rgba(30, 48, 47, 0.8);
      margin-bottom: 5vw;
      
      @media only screen and (max-width: 750px) {
        color: #1e302f;
        font-size: 8vw;
        margin-bottom: 15vw;
      }
    }

    .icon-group {   
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      color: #1e302f;

      @media only screen and (max-width: 750px) {
        flex-direction: column;
      }
    }

    .icon-block {
      margin: 2% 0;
      width: 33%;
      font-size: 2vw;

      @media only screen and (max-width: 750px) {
        margin-top: 20vw;
        width: 100%;
        font-size: 6vw;
      }

      .icon {
        max-width: 8.5vw;
        margin-bottom: 5%;

        @media only screen and (max-width: 750px) {
          max-width: 50vw;
        }

      }
    }
  }

  img {
    max-width: 100%;
  }
}
</style>
