<template>
  <Header />
  <Content />
  <Footer />
</template>

<script>
import Header from './components/Header.vue'
import Content from './components/Content.vue'
import Footer from './components/Footer.vue'


export default {
  name: 'App',
  components: {
    Header,
    Content,
    Footer
  }
}
</script>

<style>

html * {
  font-family: 'Noto Serif HK', serif;
  box-sizing: border-box;
}

#app {
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  color: #FFFFFF;
  position: relative;
  overflow: hidden;
}
</style>
