<template>
  <div class="header">
    <img src="../assets/logo.png" alt="" srcset="">
  </div>
</template>

<script>
export default {
  name: 'TheHeader',
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  padding: 2.6vw 13vw;
  text-align: left;

  @media only screen and (max-width: 750px) {
    padding: 5vw 6vw;
  }

  img {
    width: 100%;
    max-width: 14.5vw;

    @media only screen and (max-width: 750px) {
      max-width: 50vw;
    }
  }
}
</style>
