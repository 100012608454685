<template>
  <div class="footer">
    千河科技（香港）有限公司<br/>
    深圳前海山千河科技有限公司<br/>
    © {{ currentYear }} QianRiver Tech (HK) Ltd  All Rights Reserved
  </div>
</template>

<script>
export default {
  name: 'TheFooter',
  data() {
    return {
      currentYear: new Date().getFullYear()
    }
  }

}
</script>

<style scoped>
.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 7vw;
  background: #3871FF;

  font-weight: 400;
  font-size: 1vw;
  line-height: 1.7;
  color: #FFFFFF;

  @media only screen and (max-width: 750px) {
    height: 20vw;
    font-size: 3vw;
    line-height: normal;
  }
}
</style>
